<template>
  <div
    id="animation-container"
    :class="{
      'bounce-animation': playAnimation,
      'bounce-fixed-bottom': true,
      'bounce-bonus-text': true,
    }"
  >
    {{ czxpAward > 0 ? "+" : "" }}{{ czxpAward }}
    <img
      class="bounce-coin-size"
      src="@/assets/zoomTokenCoin.svg"
      alt="zoom coin"
    />
  </div>
</template>

<script>
/*
  Lottie animation files can be referenced from:
  https://zoombies.world/lotties/18188-stars-in-space.json
  and previewed at : https://lottiefiles.com/preview

 lotties/18188-stars-in-space.json
 lotties/25692-outer-space.json
 lotties/29238-rocket-in-space-transparent-background.json
 lotties/33886-check-okey-done.json
 lotties/37674-sparkle-filter.json
 lotties/4899-new-label.json
 lotties/58666-sputnik-mission-launch.json
 lotties/61280-star-blast.json
 lotties/68314-starfall.json
 lotties/70197-cohetes.json
 lotties/75315-rocket.json
*/

import { mapGetters } from "vuex";

export default {
  name: "CzxpRewardEffect",
  data() {
    return {
      playAnimation: false,
      czxpAward: 18,
    };
  },
  methods: {
    animate: function (value) {
      this.playAnimation = true;
      this.czxpAward = parseInt(value).toLocaleString();
      const czxp_bonus_sound = new Audio(require("../assets/czxp_gain.wav"));
      czxp_bonus_sound.play();

      setTimeout(() => {
        this.playAnimation = false;
      }, 3000);
    },
  },
  computed: {
    ...mapGetters({
      getZoomBalance: "blockChain/getZoomBalance",
    }),
  },
  watch: {
    getZoomBalance(val, oldVal) {
      if (val && oldVal && val !== oldVal) {
        this.animate(val - oldVal);
      }
    },
  },
};
</script>

<style scoped>
/** Czxp bounce effect **/

#animation-container {
  display: none;
}

.bounce-animation {
  display: block !important;
  position: relative;
  animation: bounce 4s 1;
}

@keyframes bounce {
  0%,
  10%,
  20%,
  30%,
  50%,
  100% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    transform: translateY(-90px);
    opacity: 1;
  }
  20% {
    transform: translateY(-70px);
    opacity: 1;
  }
  30% {
    transform: translateY(-90px);
    opacity: 1;
  }
  40% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.bounce-coin-size {
  width: 1em;
  position: relative;
  top: -1px;
}
.bounce-bonus-text {
  text-shadow: 1px 1px #013220;
  font-weight: bold;
  color: green;
  font-size: 22px;
  font-face: sans-serif;
}
.bounce-fixed-bottom {
  position: fixed;
  top: 150px;
  right: 10px;
}
</style>
